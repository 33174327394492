import getPropsOrFieldValues from './getPropsOrFieldValues';

const getComponentCollectionProps = (componentKeys = [], items = []) => {
  if (!componentKeys?.length > 0 || !items?.length > 0) return [];

  return componentKeys.map(componentKey =>
    items
      .filter(item => {
        return item.key?.includes(componentKey);
      })
      .map(component => getPropsOrFieldValues(component?.props))
  );
};

export default getComponentCollectionProps;
