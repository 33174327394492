import React from 'react';
import { css, cx } from 'linaria';
import Image from '@jetshop/ui/Image/Image';
import { paddingForAspectRatio } from '@jetshop/ui/Image/utils/paddingForAspectRatio';
import { Below } from '@jetshop/ui/Breakpoints';
import { theme } from '../../../Theme';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';

export const componentKeyMedia = 'component-media';

const mediaCSS = css`
  display: block;
  position: relative;

  div[data-flight-image-children] {
    position: absolute !important;
  }
`;

const videoCSS = css`
  &__video-placeholder,
  &__video {
    width: 100%;
    height: 100%;
    position: absolute !important;
    left: 0;
    top: 0;
    object-fit: cover;
  }
`;

const imageCaptionCSS = css`
  margin: auto;

  &__caption {
    padding: 0 ${theme.space[2]};
    margin: ${theme.space[2]} auto ${theme.space[3]};
    text-align: center;
  }
`;

const baseCSS = mediaCSS;

const MaybeWithCaption = ({ children, imageCaption }) => {
  return imageCaption ? (
    <figure className={imageCaptionCSS}>
      {children}
      <figcaption
        className={`${imageCaptionCSS}__caption`}
        dangerouslySetInnerHTML={{ __html: imageCaption }}
      />
    </figure>
  ) : (
    children
  );
};

const MediaElement = ({
  children,
  video,
  videoPlaceholderImage,
  aspect,
  videoControls = false,
  videoAutoplay = true,
  videoUnmuted = false,
  videoNoLoop = false,
  ...imageProps
}) => {
  return video ? (
    <div
      className={videoCSS}
      style={{ paddingBottom: paddingForAspectRatio(aspect) }}
    >
      {videoPlaceholderImage && (
        <Image
          className={`${videoCSS}__video-placeholder`}
          fillAvailableSpace
          cover
          src={videoPlaceholderImage}
          critical
          quality={20}
        />
      )}
      <video
        className={`${videoCSS}__video`}
        controls={videoControls}
        autoPlay={videoAutoplay && !videoUnmuted}
        playsInline={videoAutoplay && !videoUnmuted}
        muted={!videoUnmuted}
        loop={!videoNoLoop}
      >
        <source src={video} />
        Your browser does not support the video tag.
      </video>
      <div data-flight-image-children>{children}</div>
    </div>
  ) : (
    <Image aspect={aspect} {...imageProps}>
      {children}
    </Image>
  );
};

const Media = props => {
  const {
    className,
    video,
    videoPlaceholderImage,
    imageDesktop,
    aspectDesktop = '16:9',
    imageMobile,
    aspectMobile,
    critical,
    videoControls,
    videoAutoplay,
    videoUnmuted,
    videoNoLoop,
    imageCaption
  } = getPropsOrFieldValues(props);

  if (!imageDesktop && !video) return null;

  return (
    <div className={cx(baseCSS, className)}>
      <Below breakpoint="md">
        {matches => (
          <MaybeWithCaption imageCaption={imageCaption}>
            <MediaElement
              video={video}
              videoPlaceholderImage={videoPlaceholderImage}
              src={matches ? imageMobile ?? imageDesktop : imageDesktop}
              aspect={matches ? aspectMobile ?? aspectDesktop : aspectDesktop}
              alt={imageCaption}
              cover
              critical={critical}
              videoControls={videoControls}
              videoAutoplay={videoAutoplay}
              videoUnmuted={videoUnmuted}
              videoNoLoop={videoNoLoop}
            >
              {props?.children}
            </MediaElement>
          </MaybeWithCaption>
        )}
      </Below>
    </div>
  );
};

export default Media;
export { Media };
