import React from 'react';
import { css, cx } from 'linaria';
import { theme } from '../../../Theme';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import { buttonBaseCSS, TrendLink } from '../../../ui/Button';
import createClassNames from '../../Utils/createClassNames';
import MaybeLink from '../../MaybeLink';
import { arrowButtonMDCSS } from '../../ThemeClasses';

export const componentKeyContent = 'component-content';
export const componentKeyRawHTML = 'component-raw-html';

const contentCSS = css`
  &__pre-title {
    margin-bottom: ${theme.space[2]};
    font-size: 1rem;

    > * {
      font-weight: 400;
    }
  }

  &__html {
    h1,
    h2,
    h3,
    h4 {
      font-size: 1.625rem;
    }

    // IFRAME FINN.NO

    ${theme.below.md} {
      .iframe-desktop {
        display: none;
      }
    }

    ${theme.above.md} {
      .iframe-mobile {
        display: none;
      }
    }
  }

  &__button-wrapper {
    position: relative;
    margin-top: ${theme.space[3]};
  }

  &__button {
    display: inline-flex;
  }

  &__button-arrow-cta {
    display: inline-flex;
    justify-content: flex-start;
    height: auto;
    padding: ${theme.space[1]} 0;
    border: 0;
    background: transparent;
    font-size: 1.625rem;
    color: ${theme.colors.white};

    &:hover {
      border: 0;
      background: transparent;
      color: ${theme.colors.white};
    }
  }

  ${theme.above.md} {
    &__button-wrapper {
      margin-top: ${theme.space[3]};
    }
  }

  &__button-arrow-cta {
    &:hover {
      &:after {
        right: -2px;
      }
    }

    &:after {
      content: '';
      display: block;
      height: 2.5rem;
      width: 2.5rem;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      transition: right ${theme.transitions.positionBase};
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39.304' height='38.725' viewBox='0 0 39.304 38.725'%3E%3Cg transform='translate(-141.104 -385.725)'%3E%3Cline x2='16.512' transform='translate(152.5 405.087)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1.5'/%3E%3Cline x2='4.587' y2='4.587' transform='translate(164.425 400.5)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1.5'/%3E%3Cline y1='4.587' x2='4.587' transform='translate(164.425 405.087)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1.5'/%3E%3Cg transform='translate(141.104 385.725)' fill='none' stroke='%23fff' stroke-width='1.5'%3E%3Crect width='39.304' height='38.725' rx='19.362' stroke='none'/%3E%3Crect x='0.75' y='0.75' width='37.804' height='37.225' rx='18.612' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A")
        no-repeat center/contain;
    }
  }
`;

const base = contentCSS;

const Content = props => {
  const {
    children,
    className,
    preTitle,
    html,
    arrowCTA = false,
    buttonArrowMD = false,
    link,
    linkText,
    clickableArea = false
  } = getPropsOrFieldValues(props);

  if (!link && !preTitle && !html && !children) return null;

  return (
    <div className={cx(base, className ? `${className}__content` : null)}>
      {preTitle && (
        <div
          className={createClassNames([base, className], '__pre-title')}
          dangerouslySetInnerHTML={{ __html: preTitle }}
        />
      )}
      {children}
      {html && (
        <div
          className={cx(createClassNames([base, className], '__html'))}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      )}
      {link && linkText && (
        <div
          className={createClassNames([base, className], '__button-wrapper')}
        >
          <MaybeLink
            elementTarget={TrendLink}
            elementFallback={'span'}
            useLink={!clickableArea}
            link={link}
            className={cx(
              arrowCTA
                ? createClassNames([base, className], '__button-arrow-cta')
                : createClassNames([base, className], '__button'),
              buttonArrowMD ? arrowButtonMDCSS : null,
              clickableArea ? buttonBaseCSS : null
            )}
          >
            {linkText}
          </MaybeLink>
        </div>
      )}
    </div>
  );
};

export default Content;
export { Content };
