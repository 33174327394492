import React from 'react';
import { Below } from '@jetshop/ui/Breakpoints';
import ProductGrid from '../../../CategoryPage/ProductGrid';
import ProductSlider from '../../ProductSlider';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import { sectionTitleCSS } from '../../ThemeClasses';
import Layout, { spliceLayout } from '../Components/Layout';

const ProductSliderSection = ({ products }) => (
  <div>
    <ProductSlider products={products} />
  </div>
);

const Products = props => {
  const [children, layoutProps] = spliceLayout(props);

  if (!layoutProps) return null;

  const { title } = getPropsOrFieldValues(props);

  const products = children
    ?.map(child => child?.props?.product.value)
    ?.filter(Boolean);

  return products?.length > 0 ? (
    <Layout {...layoutProps} container={'fullwidth'}>
      <>
        {title && (
          <Layout container={layoutProps.container}>
            <div
              className={sectionTitleCSS}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Layout>
        )}
        {children?.length > 4 ? (
          <ProductSliderSection products={products} />
        ) : (
          <Below breakpoint="md">
            {matches =>
              matches ? (
                <ProductSliderSection products={products} />
              ) : (
                <Layout container={layoutProps.container}>
                  <ProductGrid products={products} />
                </Layout>
              )
            }
          </Below>
        )}
      </>
    </Layout>
  ) : null;
};

export default Products;
