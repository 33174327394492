import Banner from './Root/Banner';
import Products from './Root/Products';
import Cards from './Root/Cards';
import Card from './Components/Card';

export const shared = {
  BANNER: Banner,
  'COMPONENT-CARD': Card,
  'COMPONENT-CONTENT': () => null,
  'COMPONENT-RAW-HTML': () => null,
  'COMPONENT-LAYOUT': () => null,
  'COMPONENT-MEDIA': () => null,
  'COMPONENT-PRODUCT': () => null,
  CARDS: Cards,
  PRODUCTS: Products
};

export default shared;
