import React from 'react';
import { cx } from 'linaria';
import { Below } from '@jetshop/ui/Breakpoints';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import NativeScroll from '../../NativeScroll';
import { sectionTitleCSS } from '../../ThemeClasses';
import Layout, {
  getLayoutClass,
  layoutKeyGap,
  spliceLayout
} from '../Components/Layout';

const SimpleCards = props => {
  const [children, layoutProps] = spliceLayout(props);

  if (!layoutProps) return null;

  const { title, mobileLayout = 'column' } = getPropsOrFieldValues(props);

  return children?.length > 0 ? (
    <Layout {...layoutProps} container={'fullwidth'} gap={false}>
      <>
        {title && (
          <Layout container={layoutProps.container}>
            <div
              className={sectionTitleCSS}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Layout>
        )}

        <Below breakpoint="md">
          {matches => (
            <>
              {matches ? (
                <Layout container={layoutProps.container} mdRow={true}>
                  {mobileLayout === 'sidescroll' ? (
                    <NativeScroll
                      className={cx(
                        getLayoutClass(layoutKeyGap, layoutProps?.gap),
                        'gc-md-row'
                      )}
                    >
                      {children}
                    </NativeScroll>
                  ) : (
                    <Layout gap={layoutProps?.gap}>{children}</Layout>
                  )}
                </Layout>
              ) : (
                <Layout
                  container={layoutProps.container}
                  gap={layoutProps?.gap}
                >
                  {children}
                </Layout>
              )}
            </>
          )}
        </Below>
      </>
    </Layout>
  ) : null;
};

export default SimpleCards;
