import React from 'react';
import { cx } from 'linaria';
import getPropsOrFieldValues, {
  checkPropValue
} from '../../Utils/getPropsOrFieldValues';
import {
  containerClass,
  marginClass,
  gapClass,
  layoutBase
} from '../../ThemeClasses';

export const componentKeyLayout = 'component-layout';

export const layoutKeyContainer = 'container';
export const layoutKeyMargin = 'margin';
export const layoutKeyGap = 'gap';

// TODO - Set prefix as variable and share them between ThemeClasses
const layoutClassesBaseMap = {
  [layoutKeyContainer]: {
    baseClass: containerClass,
    prefix: 'cc-'
  },
  [layoutKeyMargin]: {
    baseClass: marginClass,
    prefix: 'mc-'
  },
  [layoutKeyGap]: {
    baseClass: gapClass,
    prefix: 'gc-'
  }
};

export const getLayoutClass = (layoutKey, layoutValue) => {
  const subClassValue = checkPropValue(layoutValue);
  const { prefix, baseClass } = layoutClassesBaseMap[layoutKey];

  return subClassValue
    ? prefix
      ? cx(baseClass, `${prefix}${subClassValue}`)
      : null
    : null;
};

export const createLayoutClasses = layoutProps =>
  Object.entries(layoutProps)?.reduce(
    (acc, [layoutKey, layoutValue]) => [
      ...acc,
      getLayoutClass(layoutKey, layoutValue)
    ],
    []
  );

export const Layout = ({
  children,
  className,
  style = null,
  container = null,
  margin = null,
  gap = null,
  fullwidthMobile = null
}) => {
  if (gap !== false && children?.length > 1) {
    gap = gap ? gap : 'none';
  }

  const layoutClasses = createLayoutClasses({
    [layoutKeyContainer]: container,
    [layoutKeyMargin]: margin,
    [layoutKeyGap]: gap
  });

  return (
    <div
      className={cx(
        layoutBase,
        className,
        ...layoutClasses,
        fullwidthMobile ? 'cc-fullwidth-mobile' : null
      )}
      style={style}
    >
      {children?.length > 1 && gap !== false
        ? children.map((child, i) => <div key={i}>{child}</div>)
        : children}
    </div>
  );
};

export const spliceLayoutFromChildren = children => {
  const layoutIndex = children.findIndex(child =>
    child?.key.includes(componentKeyLayout)
  );

  children.splice(layoutIndex, 1);

  return children;
};

export const spliceLayout = props => {
  const validChildren = props?.children?.filter(Boolean);

  if (!validChildren?.length > 0) return [null, null];

  const layoutPropsData = props.children?.find(
    child => child?.key.indexOf(componentKeyLayout) !== -1
  )?.props;

  const children = spliceLayoutFromChildren(props.children);
  const layoutProps = getPropsOrFieldValues(layoutPropsData);

  return [children, layoutProps];
};

export const withLayout = ({ props, customLayoutCSS }) => {
  const [children, layoutProps] = spliceLayout(props);

  return (
    <Layout {...layoutProps} className={cx(customLayoutCSS)}>
      {children}
    </Layout>
  );
};

export default Layout;
