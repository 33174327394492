import getPropsOrFieldValues from './getPropsOrFieldValues';

const getComponentProps = (componentKeys = [], items = []) => {
  if (!componentKeys?.length > 0 || !items?.length > 0) return [];

  return componentKeys.map(componentKey =>
    getPropsOrFieldValues(
      items.find(item => item.key?.includes(componentKey))?.props
    )
  );
};

export default getComponentProps;
