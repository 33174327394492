import React from 'react';
import { css, cx } from 'linaria';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import { theme } from '../../../Theme';
import MaybeLink from '../../MaybeLink';
import Media from './Media';

const simpleCardCSS = css`
  a {
    text-decoration: none;
  }

  &--zoom &__image-wrapper {
    transition: ${theme.transitions.boxShadow};
  }

  &--zoom &__image {
    overflow: hidden;
  }

  &--zoom div[data-flight-image-container] {
    transform: scale(1);
    transition: ${theme.transitions.imageScale};
    will-change: transform;
  }

  &--zoom:hover {
    div[data-flight-image-container] {
      transform: scale(1.025);
    }
  }

  &--zoom:hover &__image-wrapper {
     box-shadow: ${theme.commonStyles.boxShadow};
  }

  &__text {
    margin-top: ${theme.space[2]};
    font-size: ${theme.themeFontSizes['xl']};
  }
`;

const ctaArrowCSS = css`
  position: relative;

  &:hover {
    &:before {
      opacity: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.1;
    z-index: 1;
    transition: ${theme.transitions.opacity};
  }

  &__wrapper {
    width: 100%;
    position: absolute;
    bottom: ${theme.space[4]};
    padding-left: ${theme.space[3]};
    color: ${theme.colors.white};
    z-index: 2;
  }

  &__text {
    &:after {
      content: '';
      display: block;
      height: 2.5rem;
      width: 2.5rem;
      position: absolute;
      top: 50%;
      right: ${theme.space[3]};
      transform: translateY(-50%);
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='39.304' height='38.725' viewBox='0 0 39.304 38.725'%3E%3Cg transform='translate(-141.104 -385.725)'%3E%3Cline x2='16.512' transform='translate(152.5 405.087)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1.5'/%3E%3Cline x2='4.587' y2='4.587' transform='translate(164.425 400.5)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1.5'/%3E%3Cline y1='4.587' x2='4.587' transform='translate(164.425 405.087)' fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='1.5'/%3E%3Cg transform='translate(141.104 385.725)' fill='none' stroke='%23fff' stroke-width='1.5'%3E%3Crect width='39.304' height='38.725' rx='19.362' stroke='none'/%3E%3Crect x='0.75' y='0.75' width='37.804' height='37.225' rx='18.612' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A")
        no-repeat center/contain;
    }
  }
`;

export const iconCardsLayoutCSS = css`
  ${theme.below.md} {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;

    && {
      column-gap: ${theme.space[2]};
      row-gap: ${theme.space[2]};
    }
  }
`;

const baseCSS = simpleCardCSS;

const SimpleCard = props => {
  const {
    imageDesktop,
    aspectDesktop,
    imageMobile,
    aspectMobile,
    link,
    text,
    hoverAnimation,
    useCaption,
    ctaArrow
  } = getPropsOrFieldValues(props);

  return (
    <div
      className={cx(
        baseCSS,
        hoverAnimation && hoverAnimation !== 'none'
          ? `${baseCSS}--${hoverAnimation}`
          : null
      )}
    >
      <MaybeLink
        arrowCTA={ctaArrow}
        link={link}
        elementFallback={React.Fragment}
        disableButtonStyle={true}
      >
        <div
          className={cx(
            `${baseCSS}__image-wrapper`,
            ctaArrow ? ctaArrowCSS : ''
          )}
        >
          <Media
            className={`${baseCSS}__image`}
            imageDesktop={imageDesktop}
            aspectDesktop={aspectDesktop}
            imageMobile={imageMobile}
            aspectMobile={aspectMobile}
            imageCaption={text && useCaption ? text : null}
          />
          {ctaArrow && text && (
            <div className={`${ctaArrowCSS}__wrapper`}>
              <h3 className={`${ctaArrowCSS}__text`}>{text}</h3>
            </div>
          )}
        </div>
      </MaybeLink>
      {text && !ctaArrow && !useCaption && (
        <h3 className={`${baseCSS}__text`}>
          <MaybeLink ctaArrow={ctaArrow} link={link}>
            {text}
          </MaybeLink>
        </h3>
      )}
    </div>
  );
};

export default SimpleCard;
