import React from 'react';
import { css, cx } from 'linaria';
import { Below } from '@jetshop/ui/Breakpoints';
import { theme } from '../../../Theme';
import getComponentProps from '../../Utils/getComponentProps';
import getPropsOrFieldValues from '../../Utils/getPropsOrFieldValues';
import getComponentCollectionProps from '../../Utils/getComponentCollectionProps';
import Content, { componentKeyContent } from '../Components/Content';
import Media, { componentKeyMedia } from '../Components/Media';
import Layout, {
  componentKeyLayout,
  getLayoutClass,
  layoutKeyGap
} from '../Components/Layout';

const bannerCSS = css`
  color: ${theme.colors.white};
  position: relative;

  &__content {
    position: absolute;
  }

  &--center &__content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  &--left &__content {
    left: 0;
    transform: translateY(-50%);
    text-align: left;
  }

  &--bottom-left &__content {
    top: auto;
    bottom: ${theme.space[2]};
    left: 0;
    transform: none;
    text-align: left;
  }

  &--right &__content {
    right: 0;
    transform: translateY(-50%);
    text-align: right;
  }

  ${theme.below.md} {
    &__html {
      min-width: 320px;

      h1,
      h2,
      h3 {
        font-size: ${theme.themeFontSizes['4xl']} !important;
      }
    }
  }
`;

const baseCSS = bannerCSS;

const MaybeBelow = ({ children, useBelow }) =>
  useBelow ? (
    <Below breakpoint="md">{matches => children(matches)}</Below>
  ) : (
    children(false)
  );

const Banner = props => {
  const [propsLayout, propsContent] = getComponentProps(
    [componentKeyLayout, componentKeyContent],
    props?.children
  );

  const [collectionPropsMedia] = getComponentCollectionProps(
    [componentKeyMedia],
    props?.children
  );

  const {
    contentPosition = 'center',
    hideSecondImageInMD
  } = getPropsOrFieldValues(props);

  const multipleMedia = collectionPropsMedia?.length > 1;

  return (
    <Layout
      {...propsLayout}
      gap={false}
      className={cx(
        baseCSS,
        contentPosition ? `${baseCSS}--${contentPosition}` : null
      )}
    >
      <>
        {!multipleMedia ? (
          <Media {...collectionPropsMedia[0]} className={baseCSS} />
        ) : (
          <MaybeBelow useBelow={hideSecondImageInMD}>
            {matches => (
              <div
                className={getLayoutClass(
                  layoutKeyGap,
                  propsLayout?.gap ?? 'none'
                )}
              >
                {collectionPropsMedia?.map((mediaComponentProps, i) =>
                  i === 1 && hideSecondImageInMD && matches ? null : (
                    <div key={i}>
                      <Media {...mediaComponentProps} className={baseCSS} />
                    </div>
                  )
                )}
              </div>
            )}
          </MaybeBelow>
        )}
      </>
      <Content {...propsContent} className={baseCSS} buttonArrowMD={true} />
    </Layout>
  );
};

export default Banner;
