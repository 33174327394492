import React from 'react';
import { Link } from 'react-router-dom';
import { cx } from 'linaria';
import { buttonBaseCSS, linkStylesCSS } from '../ui/Button';

export const MaybeLink = ({
  children,
  className,
  elementFallback = 'div',
  elementTarget = Link,
  arrowCTA = false,
  disableButtonStyle = false,
  link
}) => {
  const internalLink = link?.charAt(0) === '/';
  const linkElement = link
    ? !internalLink
      ? 'a'
      : elementTarget
    : elementFallback;

  if (internalLink) {
    return React.createElement(
      linkElement,
      { to: link, ...(className ? { className: className } : {}) },
      children
    );
  } else {
    return React.createElement(
      linkElement,
      {
        href: link,
        className: cx(
          arrowCTA
            ? null
            : cx(disableButtonStyle ? null : cx(buttonBaseCSS, linkStylesCSS))
        )
      },
      children
    );
  }
};

export default MaybeLink;
